.toggle-container {
  width: 50px;
  height: 25px;
  border-radius: 20px;
  border: 1px solid #999;
  background-color: white;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-icon {
  width: 15px;
  height: 15px;
  margin: 0px 5px;
}

.toggle-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #999;
  position: absolute;
  cursor: pointer;
}
