.intro-container {
  display: flex;
  height: 100vh;
}

.intro-left,
.intro-right {
  flex: 1;
}

.intro-left {
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-right {
  position: relative;
}

.intro-wrapper {
  padding: 50px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.intro-title-intro {
  font-weight: 300;
}

.intro-name {
}

.intro-titles {
  height: 50px;
  overflow: hidden;
}

.intro-titles-wrapper {
  height: 100%;
  animation: move 10s ease-in-out infinite alternate;
}

@keyframes move {
  25% {
    transform: translateY(-50px);
  }
  50% {
    transform: translateY(-100px);
  }
  75% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(-200px);
  }
}

.intro-title-item {
  height: 50px;
  font-size: 30px;
  font-weight: bold;
  color: #b6ae47;
  display: flex;
  align-items: center;
}

.intro-description {
  text-align: justify;
}

.more-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.profile-bg {
  clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
  background: #b6ae47;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.profile-image-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-picture {
  width: 65%;
  height: 65%;
  object-fit: cover;
  position: absolute;
  border-radius: 50%;
}

/* This is for Phones */
@media screen and (max-width: 480px) {
  .intro-container {
    flex-direction: column;
  }

  .intro-wrapper {
    padding: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100%;
  }

  .intro-description {
    display: none;
  }
}

/* This is for Tablets */
@media screen and (max-width: 768px) {
}

/* This is for small computer screens */
@media screen and (max-width: 1200px) {
}
