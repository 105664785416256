.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d4d4d4;
}

.viewer {
  height: 98vh;
  width: 45vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
