.card-container {
  width: 30%;
  height: 35vh;
  margin: 20px 10px;
  border: 2px solid rgb(242, 242, 242);
  border-radius: 10px;
  overflow: hidden;
}

.card-browser {
  background-color: rgb(242, 242, 242);
  height: 20px;
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 2;
}

.card-circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  color: black;
  margin: 3px;
}

.card-image {
  width: 100%;
  transition: all 10s ease;
}

.card-container:hover .card-image {
  transform: translateY(-100%);
}

/* This is for Phones */
@media screen and (max-width: 480px) {
  .card-container {
    width: 40%;
    height: 15vh;
  }
}

/* This is for Tablets */
@media screen and (max-width: 768px) {
}

/* This is for small computer screens */
@media screen and (max-width: 1200px) {
}
