.App {
  font-family: "Poppins", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 70px;
}

h2 {
  font-size: 40px;
}

p {
  font-size: 18px;
}
