.about-container {
  height: 100vh;
  display: flex;
}

.about-left {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.about-right {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-card.bg {
  position: absolute;
  top: 50px;
  left: 50px;
  background-color: #333;
}

.about-card {
  width: 60%;
  height: 70vh;
  border-radius: 30px;
  position: absolute;
  overflow: hidden;
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-title {
}

.about-subtitle {
  margin: 20px 0px;
  font-weight: 500;
}

.about-description {
  margin-right: 100px;
  font-weight: 300;
  text-align: justify;
}

/* This is for Phones */
@media screen and (max-width: 480px) {
  .about-container {
    flex-direction: column;
    text-align: center;
    margin-top: 50px;
  }
  .about-left {
    width: 100%;
  }
  .about-card {
    height: 30vh;
  }

  .about-card.bg {
    display: none;
  }
  .about-right {
    padding: 20px;
  }
}

/* This is for Tablets */
@media screen and (max-width: 768px) {
}

/* This is for small computer screens */
@media screen and (max-width: 1200px) {
}
