.pl-container {
  height: 100vh;
  padding: 50px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.pl-intro {
  width: 65%;
}

.pl-intro-title {
  font-weight: 600;
}

.pl-intro-description {
  margin: 20px 0px;
}

.pl-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* This is for Phones */
@media screen and (max-width: 480px) {
  .pl-container {
    padding: 10px;
    margin-bottom: 100px;
  }
  .pl-intro {
    width: 100%;
  }
  .pl-intro-description {
    display: none;
  }
}

/* This is for Tablets */
@media screen and (max-width: 768px) {
}

/* This is for small computer screens */
@media screen and (max-width: 1200px) {
}
