.contact-container {
  height: 100vh;
  position: relative;
}

.contact-bg {
  width: 20px;
  height: 100%;
  background-color: #b6ae47;
  position: absolute;
}

.contact-wrapper {
  padding: 50px;
  display: flex;
}

.contact-left {
  flex: 1;
}

.contact-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-title {
  font-size: 60px;
  width: 80%;
}

.contact-info {
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0px;
  font-size: 30px;
  font-weight: 300;
}

.contact-info-item > svg {
  margin-right: 20px;
}

.contact-description {
  font-weight: 200;
  font-size: 20px;
}

form {
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
}

form > input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0px;
  font-size: 14px;
  padding-left: 10px;
  background-color: rgb(249, 248, 248);
  border-radius: 10px 10px 0px 0px;
}

form > textarea {
  width: 100%;
  font-family: "Poppins", sans-serif;
  margin: 10px 0px;
}

form > button {
  border: none;
  padding: 15px;
  background-color: #b6ae47;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.done-message {
  margin-top: 30px;
  text-align: center;
  font-size: 35px;
  font-weight: 300;
  border-bottom: 1px solid rgb(242, 242, 242);
}

/* This is for Phones */
@media screen and (max-width: 480px) {
  .contact-container {
    margin-top: 50px;
  }
  .contact-wrapper {
    flex-direction: column;
    padding: 0px 50px;
  }
  .contact-title {
    font-size: 30px;
    text-align: center;
  }
  .contact-info-item {
    margin: 20px;
    font-size: 15px;
    width: 100%;
  }
  .contact-description {
    display: none;
  }

  form {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
  }

  form > input {
    width: 35%;
    height: 40px;
    margin: 10px;
  }
  form > button {
    margin-top: 10px;
  }
}

/* This is for Tablets */
@media screen and (max-width: 768px) {
}

/* This is for small computer screens */
@media screen and (max-width: 1200px) {
}
